import {
    enable as enableDarkMode,
    disable as disableDarkMode,
    isEnabled as isDarkReaderEnabled
} from 'darkreader';

export class App {
    #darkModeButton = document.querySelector('#dark-toggle');
    logo = document.querySelector('.logo');
    constructor() {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        if (darkThemeMq.matches) {
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10

            });
            this.logo.src = require('../images/logodark.png');
        }

        this.#darkModeButton.addEventListener('click', () => {
            this.toggleDarkMode();
        });
    }

    toggleDarkMode() {
        if (isDarkReaderEnabled()) {
            disableDarkMode();
            this.logo.src = require('../images/logo.png');

        }
        else {
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10
            });
            this.logo.src = require('../images/logodark.png');
        }
    }
}