import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/brands';

import './styles/style.css';
import './styles/float.css';
import {App} from "./modules/app";

document.addEventListener('DOMContentLoaded', () =>{
    // Code goes here
    console.log("DOM loaded");
    const app = new App();

    window.addEventListener('DOMContentLoaded', function () {
        var button = document.getElementById('modalButton');

        // click on the button when the page loads
        button.click();
    });
});